<template>

    <Layout>
        <mainSection />
    </Layout>


</template>

<script setup>

import lodash from 'lodash'
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import mainSection from '@/modules/widget/components/home/main-section'
import Layout from '@/modules/widget/components/home/Layout/Index'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'


const props = defineProps({
    id: {
        required: true,
        type: [Number, String]
    }
})

const store = useStore()
const router = useRouter()

const widget = computed(() => store.getters['widget/activeWidget'])

const checkIfOfficeExists = () => {
    if(lodash.isEmpty(widget.value)) {
        router.replace({ name: 'contacts' })
        return
    }
    const officeIds = widget.value.contacts.map(contact => contact.id)
    if(officeIds.length && !officeIds.includes(props.id)) router.replace({ name: 'contacts' })
}

watch(widget, () => {
    checkIfOfficeExists()
})

const { getContacts, toggleWidget } = useWidgetConfig()

onMounted(() => {
    getContacts()
    toggleWidget(true)
})

</script>
